import {loadSvgInline} from "./functions/replaceSVG";

window.onload = function() {
    const imgElements = document.querySelectorAll('.svg');
    imgElements.forEach(imgElement => {
      loadSvgInline(imgElement);
    });
}

function setContentHeight() {
  const windowInnerWidth = window.innerHeight;
  const newHeight = `${windowInnerWidth}px`;
  
  document.documentElement.style.setProperty('--content-height', newHeight);
}

window.addEventListener('load', setContentHeight);
window.addEventListener('resize', setContentHeight);

import "./functions/validation";