import { TonConnectUI, toUserFriendlyAddress } from '@tonconnect/ui';
import { UserRejectsError } from "@tonconnect/sdk";
import { sendGetRequest, sendPostRequest, getFromLocalStorage, showModal, getUserStat, saveToLocalStorage } from '../functions/api';
import { config } from '../../config';

let tonConnectUIInstance = null;

class TonWallet {
    constructor() {
        this.walletButton = document.querySelector('.wallet__link');
        this.walletText = this.walletButton ? this.walletButton.querySelector('.wallet__link--text') : null;
        this.isProcessing = false;
        try {
            if (!tonConnectUIInstance) {
                tonConnectUIInstance = new TonConnectUI({
                    manifestUrl: 'https://testmt.xyz/assets/tonconnect-manifest.json'
                });
            }
            this.tonConnectUI = tonConnectUIInstance;
        } catch (error) {
            console.error("Ошибка при инициализации TonConnectUI:", error);
        }
    }    

    async connect() {
        try {
            if (this.tonConnectUI.wallet) {
                console.log("Кошелек уже подключен:", this.tonConnectUI.wallet);
                return this.tonConnectUI.wallet;
            } else {
                await this.tonConnectUI.connectWallet();
                console.log("Кошелек успешно подключен:", this.tonConnectUI.wallet);
                const walletInfo = this.tonConnectUI.wallet;
                console.log("Информация о кошельке:", walletInfo);
                saveToLocalStorage('wallet_adress', walletInfo.account.address);
                this.saveWalletAddress(walletInfo.account.address)
                const base64Address = this.tonHexToBase64(walletInfo.account.address);
                const shortAddress = `${base64Address.slice(0, 4)}...${base64Address.slice(-4)}`;
                this.walletText.textContent = shortAddress;
                return this.tonConnectUI.wallet;
            }
        } catch (error) {
            console.error("Ошибка подключения к кошельку:", error);
            throw error;
        }
    }

    async disconnect() {
        try {
            await this.tonConnectUI.disconnect();
            await this.saveWalletAddress(null);
            saveToLocalStorage('wallet_adress', null);
            this.walletText.textContent = "Wallet";
            console.log("Кошелек отключен");
        } catch (error) {
            console.error("Ошибка при отключении кошелька:", error);
        }
    }
    

    async saveWalletAddress(address) {
        if (this.isProcessing) {
            console.warn("Сохранение уже выполняется, запрос отклонен.");
            return;
        }
    
        this.isProcessing = true;
    
        try {
            const response = await sendPostRequest(
                config.BASE_URL + '/wallet/save',
                { wallet_address: address },
                { "Authorization": "Bearer " + getFromLocalStorage('token') }
            );
    
            if (response.success === true) {
                console.log("Кошелек успешно сохранен в базе данных.");
                saveToLocalStorage('wallet_adress', address);
            } else {
                console.error("Ошибка при сохранении кошелька:", response.message);
            }
        } catch (error) {
            console.error("Ошибка при отправке данных кошелька на сервер:", error);
        } finally {
            this.isProcessing = false;
        }
    }
     

    getWalletInfo() {
        return this.tonConnectUI.wallet;
    }

    async sendTonPayment(amount, toAddress) {
        let transactionResult;
        try {
            const convertedAmount = this.convertAmount(amount);

            const txData = {
                validUntil: Math.round(Date.now() / 1000) + 600,
                messages: [{ address: toAddress, amount: convertedAmount.toString() }]
            };

            transactionResult = await this.tonConnectUI.sendTransaction(txData);
            console.log("Транзакция успешно выполнена:", transactionResult);

            return { status: 'success', data: transactionResult };
        } catch (error) {
            console.error("Ошибка транзакции:", error);

            if (error instanceof UserRejectsError) {
                console.error("Пользователь отклонил запрос.");
                return { status: 'false', error: 'The user declined the request.' };
            }

            if (error.message.includes('insufficient funds')) {
                console.error("Недостаточно средств на кошельке.");
                return { status: 'false', error: 'Insufficient funds.' };
            }

            return { status: 'false', error: error.message };
        } /* finally {
            await this.sendTransactionDataToServer(transactionResult);
        } */
    }

    convertAmount(nanoTonValue) {
        return parseFloat(nanoTonValue) * 1000000000;
    }

    async sendTransactionDataToServer(transactionResult) {
        try {
            const response = await sendPostRequest(config.BASE_URL + '/transaction/confirm', {
                transactionData: transactionResult
            }, {
                "Authorization": "Bearer " + getFromLocalStorage('token')
            });

            if (response.success) {
                console.log("Данные транзакции успешно отправлены на сервер.");
            } else {
                console.error("Ошибка при отправке данных на сервер:", response.message);
            }
        } catch (error) {
            console.error("Ошибка при отправке данных транзакции на сервер:", error);
        }
    }

    isConnected() {
        return this.connected;
    }

    tonHexToBase64(hexAddress) {
        return toUserFriendlyAddress(hexAddress);
    }
}

export default TonWallet;