
import Animation from './animations.js';
import TaskList from './task.js';

class Menu {
    constructor(menuSelector, pageSelector, headerSelector) {
        this.menuItems = document.querySelectorAll(menuSelector);
        this.pages = document.querySelectorAll(pageSelector);
        this.header = document.querySelector(headerSelector)
        this.animation = new Animation();
        this.taskList = new TaskList();
        this.init();
    }

    init() {
        this.setupEventListeners();
        this.showPage('main');
    }

    setupEventListeners() {
        this.menuItems.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault();
                const pageToShow = item.getAttribute('menu');
                this.showPage(pageToShow);
            });
        });
    }

    showPage(pageName) {
        this.pages.forEach(page => {
            if (page.getAttribute('page') === pageName) {
                page.classList.add('active');
                this.animation.addAnimation(page, {
                    animationName: ['opacityIn'],
                    duration: ['0.3'],
                    delay: ['0'],
                    timingFunction: ['linear']
                });

                if (pageName === 'tasks') {
                    this.taskList.activate();
                } else {
                    this.taskList.deactivate();
                }
            } else {
                this.animation.addAnimation(page, {
                    animationName: ['pageOut'],
                    duration: ['0.3'],
                    delay: ['0'],
                    timingFunction: ['linear']
                }); 
                setTimeout(() => {
                    page.classList.remove('active');
                }, 300);
            }
        });

        this.updateHeader(pageName);
        this.updateMenuState(pageName);
    }

    updateHeader(pageName) {
        const referalHeader = this.header.querySelector('.referal__header');
        const profileHeader = this.header.querySelector('.profile__header');
    
        if (pageName === 'referal') {
            referalHeader.style.display = 'flex';
            profileHeader.style.display = 'none';
        } else {
            profileHeader.style.display = 'flex';
            referalHeader.style.display = 'none';
        }
    }    

    updateMenuState(activePage) {
        this.menuItems.forEach(item => {
            if (item.getAttribute('menu') === activePage) {
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }
        });
    }
}

export default Menu;
