import { auth, getUserStat, getFromLocalStorage, sendGetRequest, showModal } from '../functions/api';
import { config } from './../../config';
import Animation from './animations.js';

class Ranking {
    constructor(rankingBtnSelector, rankingWrapperSelector, backBtnSelector, contentSelector) {
        this.rankingBtn = document.querySelector(rankingBtnSelector);
        this.rankingWrapper = document.querySelector(rankingWrapperSelector);
        this.backButton = document.querySelector(backBtnSelector);
        this.content = document.querySelector(contentSelector);
        this.rankingList = this.rankingWrapper.querySelector('.ranking .ranking__list');
        this.setupEventListeners();
        this.animation = new Animation();
    }

    setupEventListeners() {
        this.rankingBtn.addEventListener('click', () => {
            this.animation.addAnimation('.ranking__btn', {
                animationName: ['rotate180'],
                duration: ['0.4'],
                delay: ['0'],
                timingFunction: ['linear']
            }); 
            this.fetchRankingData();
            setTimeout(() => {
                this.showRanking();
            }, 400);
        });        

        this.backButton.addEventListener('click', () => {
            this.hideRanking();
        });
    }

    showRanking() {
        getUserStat()
        this.rankingWrapper.classList.add('active');
        this.content.classList.add('hidden');
        /* this.rankingList.innerHTML = ''; */
    }

    hideRanking() {
        this.rankingWrapper.classList.remove('active');
        this.content.classList.remove('hidden');
    }

    fetchRankingData() {
        sendGetRequest(config.BASE_URL + '/user/top', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        }).then(response => {
            if (response.status === "success") {
                const userData = Object.values(response.data).map(user => ({
                    firstName: user.first_name,
                    income: user.income
                }));
    
                this.showRanking();

                const rankingList = document.querySelectorAll('.ranking .ranking__list');
                rankingList.forEach(list => {
                    list.innerHTML = '';
                });
            
                let position = 1;
            
                userData.forEach(user => {
                    const rankingItem = document.createElement('div');
                    rankingItem.classList.add('ranking__list__item');
            
                    const topContent = position <= 3
                        ? `<p class="top"><img src="img/svg/ranking/top-${position}.svg" alt=""></p>`
                        : `<p class="top">${position}</p>`;
            
                    rankingItem.innerHTML = `
                        ${topContent}
                        <div class="user__data">
                            <p>${user.firstName}</p>
                            <img src="img/svg/user.svg" alt="">
                        </div>
                        <div class="score">
                            <p>${user.income}</p>
                            <img src="img/svg/currency/mtube.svg" alt="">
                        </div>
                    `;
            
                    rankingList.forEach(list => {
                        list.appendChild(rankingItem.cloneNode(true));
                    });
            
                    position++;
                });
    
                this.animation.addAnimation('.ranking__list', {
                    animationName: ['zoomIn'],
                    duration: ['0.3'],
                    delay: ['0'],
                    timingFunction: ['linear']
                });
            } else {
                console.error("Ошибка получения данных рейтинга:", response);
            }
        });
    }  
    
}

export default Ranking;
