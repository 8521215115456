import ShopModal from './shopModal.js';
import { sendGetRequest, sendPostRequest, getFromLocalStorage, showModal, getUserStat } from '../functions/api';
import { config } from './../../config';
import Animation from './animations.js';
import { getTranslation } from '../_functions';

class Shop {
    constructor() {
        this.modalSystem = new ShopModal();
        this.animation = new Animation();

        this.exchangeData = null;
        this.channelBoostData = null;
        this.likesShopData = null;

        this.currentLanguage = getFromLocalStorage('language') || 'en';

        this.initButtons();
        this.initWithdrawal();

        this.initLanguageChangeListener();
    }

    initLanguageChangeListener() {
        const languageObserver = new MutationObserver(() => {
            const newLanguage = getFromLocalStorage('language') || 'en';
            if (this.currentLanguage !== newLanguage) {
                this.currentLanguage = newLanguage;
                this.updateTexts();
            }
        });

        languageObserver.observe(document.body, { childList: true, subtree: true });
    }

    updateTexts() {
        document.querySelectorAll('.exchange__list__item .title').forEach((title, index) => {
            if (index === 0) {
                title.innerHTML = `$MTUBE ${getTranslation(this.currentLanguage, 'shop.shop__modal.exchange.in')} TON`;
            } else if (index === 1) {
                title.innerHTML = `$MTUBE ${getTranslation(this.currentLanguage, 'shop.shop__modal.exchange.in')} LIKE`;
            }
        });
    }

    initButtons() {
        document.querySelectorAll('.shop__item__btn').forEach(button => {
            const btn = button.querySelector('.title').getAttribute('data-modal-name');
            const modalName = btn.toLowerCase().replace(/\s+/g, '-');
            button.addEventListener('click', () => {
                this.modalSystem.openModal(modalName);
                if (modalName === 'upgrade-channel') {
                    this.displayChannelBoostItems();
                } else if (modalName === 'buy-likes') {
                    this.displayLikesShopItems();
                } else if (modalName === 'exchange') {
                    this.displayExchangeItems();
                }
            });
        });
    }

    displayExchangeItems() {
        const exchangeList = document.querySelector('.exchange__list');
        exchangeList.innerHTML = '<div class="clear-icon"><img class="svg" src="img/load.gif" alt=""></div>';

        if (!this.exchangeData) {
            this.loadExchangeItems().then(() => {
                this.renderExchangeItems();
            });
        } else {
            this.renderExchangeItems();
        }
    }

    loadExchangeItems() {
        return sendGetRequest(config.BASE_URL + '/exchange/list', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.load__exchange__data__error'));
                return;
            }

            this.exchangeData = response.data;
        })
        .catch(error => {
            console.error('Ошибка загрузки данных обмена:', error);
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.load__exchange__data__error'));
        });
    }

    renderExchangeItems() {
        const exchangeList = document.querySelector('.exchange__list');
        exchangeList.innerHTML = ''; 

        if (this.exchangeData.views_to_ton) {
            const viewsToUsdtHTML = `
                <div class="exchange__list__item" data-exchange="views_to_ton">
                    <p class="title">$MTUBE ${getTranslation(this.currentLanguage, 'shop.shop__modal.exchange.in')} TON</p>
                    <div class="exchange">
                        <img class="from" src="img/currency/mtube.png" alt="Просмотры">
                        <span><img src="img/svg/arrow-right-2.svg" alt=""></span>
                        <img class="ton" src="img/currency/ton.png" alt="USDT">
                    </div>
                </div>`;
            exchangeList.insertAdjacentHTML('beforeend', viewsToUsdtHTML);
        }

        if (this.exchangeData.views_to_like) {
            const viewsToLikeHTML = `
                <div class="exchange__list__item" data-exchange="views_to_like">
                    <p class="title">$MTUBE ${getTranslation(this.currentLanguage, 'shop.shop__modal.exchange.in')} LIKE</p>
                    <div class="exchange">
                        <img class="from" src="img/currency/mtube.png" alt="Просмотры">
                        <span><img src="img/svg/arrow-right-2.svg" alt=""></span>
                        <img class="heart" src="img/currency/like.png" alt="Лайки">
                    </div>
                </div>`;
            exchangeList.insertAdjacentHTML('beforeend', viewsToLikeHTML);
        }

        this.modalSystem.bindExchangeSelection();
    }

    updateLanguage(newLanguage) {
        this.currentLanguage = newLanguage;
        this.refreshShopContent();
    }

    refreshShopContent() {
        if (this.exchangeData) {
            this.renderExchangeItems();
        }
        if (this.likesShopData) {
            this.renderLikesShopItems();
        }
        if (this.channelBoostData) {
            this.renderChannelBoostItems();
        }

        document.querySelectorAll('.shop__item__btn .title').forEach(button => {
            const modalName = button.getAttribute('data-modal-name');
            button.textContent = getTranslation(this.currentLanguage, `shop.buttons.${modalName}`);
        });
    }



    displayChannelBoostItems() {
        const loader = document.querySelector('.modal[data-modal-name="upgrade-channel"] .channel__boost');
        loader.innerHTML = '<div class="clear-icon"><img class="svg" src="img/load.gif" alt=""></div>';

        if (!this.channelBoostData) {
            this.loadChannelBoostItems().then(() => {
                this.renderChannelBoostItems();
            });
        } else {
            this.renderChannelBoostItems();
        }
    }

    loadChannelBoostItems() {
        return sendGetRequest(config.BASE_URL + '/channel/list-upgrade', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.load__boost__data__error'));
                return;
            }

            this.channelBoostData = response.data;
        })
        .catch(error => {
            console.error('Ошибка загрузки товаров:', error);
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.load__boost__data__error'));
        });
    }

    renderChannelBoostItems() {
        const loader = document.querySelector('.modal[data-modal-name="upgrade-channel"] .channel__boost');
        loader.innerHTML = ''; 

        Object.entries(this.channelBoostData).forEach(([amount, price]) => {
            const adjustedPrice = price / 10;

            const listItemHTML = `
                <div class="channel__boost__item">
                    <div class="left">
                        <img src="img/svg/currency/mtube.svg" alt="">
                        <p>+ <span>${adjustedPrice}</span> $MTUBE</p>
                    </div>
                    <button class="btn primary--btn" data-price="${amount}">
                        <span>${amount}</span>
                        <img class="svg" src="img/svg/currency/like-w.svg" alt="">
                    </button>
                </div>
            `;
            loader.insertAdjacentHTML('beforeend', listItemHTML);
        });

        this.bindBoostBuyButtons();
    }

    displayLikesShopItems() {
        const loader = document.querySelector('.modal[data-modal-name="buy-likes"] .buy__like');
        loader.innerHTML = '<div class="clear-icon"><img class="svg" src="img/load.gif" alt=""></div>';

        if (!this.likesShopData) {
            this.loadLikesShopItems().then(() => {
                this.renderLikesShopItems();
            });
        } else {
            this.renderLikesShopItems();
        }
    }

    loadLikesShopItems() {
        return sendGetRequest(config.BASE_URL + '/payment/list', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.load__likes__data__error'));
                return;
            }

            this.likesShopData = response.data;
        })
        .catch(error => {
            console.error('Ошибка загрузки товаров для покупки лайков:', error);
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.load__likes__data__error'));
        });
    }

    renderLikesShopItems() {
        const loader = document.querySelector('.modal[data-modal-name="buy-likes"] .buy__like');
        loader.innerHTML = ''; 

        Object.entries(this.likesShopData).forEach(([amount, price]) => {
            const listItemHTML = `
                <div class="buy__like__item">
                    <div class="image"><img src="img/shop/buy/item-${amount}.png" alt=""></div>
                    <div class="info">
                        <p>${amount} likes</p>
                        <button class="btn primary--btn" data-price="${price}" data-amount="${amount}">${price} $</button>
                    </div>
                </div>
            `;
            loader.insertAdjacentHTML('beforeend', listItemHTML);
        });

        this.bindLikesBuyButtons();
    }

    bindBoostBuyButtons() {
        document.querySelectorAll('.channel__boost__item .btn').forEach(button => {
            button.addEventListener('click', (event) => {
                const price = event.currentTarget.getAttribute('data-price');
                button.disabled = true;
    
                this.buyChannelUpgrade(price, button);
            });
        });
    }
    
    bindLikesBuyButtons() {
        document.querySelectorAll('.buy__like__item .btn').forEach(button => {
            button.addEventListener('click', (event) => {
                const amount = event.currentTarget.getAttribute('data-amount');
                button.disabled = true;

                this.purchaseLikes(amount, button);
            });
        });
    }

    bindBoostBuyButtons() {
        document.querySelectorAll('.channel__boost__item .btn').forEach(button => {
            button.addEventListener('click', (event) => {
                const price = event.currentTarget.getAttribute('data-price');
                button.disabled = true;
    
                this.buyChannelUpgrade(price, button);
            });
        });
    }
    
    bindLikesBuyButtons() {
        document.querySelectorAll('.buy__like__item .btn').forEach(button => {
            button.addEventListener('click', (event) => {
                const amount = event.currentTarget.getAttribute('data-amount');
                button.disabled = true;
    
                this.purchaseLikes(amount, button);
            });
        });
    }

    buyChannelUpgrade(price, button) {
        return sendPostRequest(config.BASE_URL + '/channel/upgrade', { price }, {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.buy__boost__error'));
                return;
            }
    
            button.disabled = false;
    
            showModal(getTranslation(this.currentLanguage, 'modal.title.success'), getTranslation(this.currentLanguage, 'shop.modal.text.buy__boost__success'), true);
            getUserStat();
        })
        .catch(error => {
            console.error('Ошибка покупки товара:', error);
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.buy__boost__error'));
            button.disabled = false;
        });
    }
    
    purchaseLikes(amount, button) {
        return sendPostRequest(config.BASE_URL + '/user/make-deposit', { amount }, {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal(
                    getTranslation(this.currentLanguage, 'shop.modal.title.buy__likes__error'), 
                    getTranslation(this.currentLanguage, 'shop.modal.text.buy__likes__error'),
                    false,
                    '',
                    '',
                    'https://i.imgur.com/7TU1DpG.png'
                );
                return;
            }
    
            button.disabled = false;
            window.location.href = response.data.url;
        })
        .catch(error => {
            console.error('Ошибка покупки:', error);
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.buy__boost__error'));
            button.disabled = false;
        });
    }

    initWithdrawal() {
        const withdrawBtn = document.getElementById('withdraw-btn');
        const amountInput = document.getElementById('amount-input');
        const walletInput = document.getElementById('wallet-combo');
        const minWarning = document.getElementById('min-warning');
        const walletError = document.getElementById('wallet-error');
        const balance = getFromLocalStorage('balance');

        document.querySelector('.balance span').textContent = balance;

        // Валидация формы
        const validateForm = () => {
            const amount = parseFloat(amountInput.value);
            const wallet = walletInput.value.trim();

            if (amount < 10 || isNaN(amount)) {
                withdrawBtn.disabled = true;
                minWarning.style.display = 'block';
            } else {
                minWarning.style.display = 'none';
            }

            if (wallet.length < 20 || wallet.length > 100) {
                walletError.style.display = 'block';
                withdrawBtn.disabled = true;
            } else {
                walletError.style.display = 'none';
            }

            if (amount >= 0.2 && wallet.length >= 20 && wallet.length <= 100) {
                withdrawBtn.disabled = false;
            } else {
                withdrawBtn.disabled = true;
            }
        };

        amountInput.addEventListener('input', validateForm);
        walletInput.addEventListener('input', validateForm);

        withdrawBtn.addEventListener('click', () => {
            const amount = parseFloat(amountInput.value);
            const wallet = walletInput.value.trim();

            if (amount > balance) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.no__money'));
                return;
            }

            withdrawBtn.disabled = true;

            this.withdrawMoney(amount, wallet).finally(() => {
                withdrawBtn.disabled = false;
            });
        });
    }

    withdrawMoney(amount, wallet) {
        sendPostRequest(config.BASE_URL + '/payout/make-withdrawal', {
            "amount": amount,
            "address": wallet
        }, {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.withdraw__error'));
                return;
            }

            const withdrawBtn = document.getElementById('withdraw-btn');
            withdrawBtn.disabled = false;

            showModal(getTranslation(this.currentLanguage, 'modal.title.success'), getTranslation(this.currentLanguage, 'shop.modal.text.withdraw__success') + `${amount} TON`, true);
            document.querySelector('.balance span').textContent = (100 - amount).toFixed(2);
            getUserStat()
        })
        .catch(error => {
            console.error('Ошибка вывода средств:', error);
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'shop.modal.text.withdraw__error'));
        });
    }
}

export default Shop;
