import Modal from './modal.js';
import { sendGetRequest, sendPostRequest, getFromLocalStorage, showModal, getUserStat } from '../functions/api';
import { config } from './../../config';
import Animation from './animations.js';

class ShopModal extends Modal {
    constructor() {
        super();
        this.animation = new Animation();
        this.loadExchangeData();
        this.bindExchangeSelection();
        this.bindExchangeFormSubmit();
        this.bindInputAmountListener();
    }

    bindInputAmountListener() {
        const modal = document.querySelector('.modal[data-modal-name="обменник"]');
        const inputField = modal.querySelector('.exchange__form input[type="number"]');
        const exchangeRateField = modal.querySelector('.exchange-rate');
        const resultField = modal.querySelector('.wallet');

        inputField.addEventListener('input', () => {
            const amount = parseFloat(inputField.value);
            if (!isNaN(amount) && amount > 0) {
                this.calculateExchangeResult(modal, amount, exchangeRateField, resultField);
            } else {
                resultField.textContent = ''; 
            }
        });
    }

    calculateExchangeResult(modal, amount, exchangeRateField, resultField) {
        if (!this.exchangeData) {
            showModal('Ошибка', 'Данные для обмена не загружены.');
            return;
        }
        
        const exchangeType = modal.getAttribute('data-exchange-type');
        let result = 0;
    
        switch (exchangeType) {
            case 'usdt_to_like':
                if (this.exchangeData.usdt_to_like) {
                    result = amount / parseFloat(this.exchangeData.usdt_to_like.exchange_rate);
                }
                break;
            case 'views_to_ton':
                if (this.exchangeData.views_to_ton) {
                    result = (amount / parseFloat(this.exchangeData.views_to_ton.count)) * parseFloat(this.exchangeData.views_to_ton.exchange_rate);
                }
                break;
            case 'views_to_like':
                if (this.exchangeData.views_to_like) {
                    result = amount / parseFloat(this.exchangeData.views_to_like.count);
                }
                break;
            default:
                showModal('Ошибка', 'Неверный тип обмена.');
                return;
        }
    
        resultField.textContent = `Вы получите: ${result.toFixed(2)}`;
    }
       

    bindExchangeSelection() {
        const modal = document.querySelector('.modal[data-modal-name="обменник"]');
        const exchangeItems = modal.querySelectorAll('.exchange__list__item');
        const formTitle = modal.querySelector('.form-title');
        const exchangeRate = modal.querySelector('.exchange-rate');
        
        const currencyIcon = modal.querySelector('.head img.mtube');
        const currencyText = modal.querySelector('.currency');
        
        exchangeItems.forEach(item => {
            item.addEventListener('click', () => {
                const exchangeType = item.getAttribute('data-exchange');
                this.showExchangeForm(exchangeType);
    
                switch (exchangeType) {
                    case 'usdt_to_like':
                        formTitle.textContent = "USDT в ЛАЙКИ";
                        exchangeRate.textContent = `Курс: 1 USDT = ${this.exchangeData.usdt_to_like.count} Лайк`;
                        currencyIcon.src = 'img/svg/ton.svg';
                        currencyIcon.alt = 'USDT';
                        currencyText.textContent = 'USDT';
                        break;
                    case 'views_to_ton':
                        formTitle.textContent = "$MTUBE в TON";
                        exchangeRate.textContent = `Курс: ${this.exchangeData.views_to_ton.count} $MTUBE = ${this.exchangeData.views_to_ton.exchange_rate} TON`;
                        currencyIcon.src = 'img/currency/mtube.png';
                        currencyIcon.alt = '$MTUBE';
                        currencyText.textContent = '$MTUBE';
                        break;
                    case 'views_to_like':
                        formTitle.textContent = "$MTUBE в LIKE";
                        exchangeRate.textContent = `Курс: ${this.exchangeData.views_to_like.count} $MTUBE = ${this.exchangeData.views_to_like.exchange_rate} LIKE`;
                        currencyIcon.src = 'img/currency/mtube.png';
                        currencyIcon.alt = '$MTUBE';
                        currencyText.textContent = '$MTUBE';
                        break;
                }
            });
        });
    
        const backButton = modal.querySelector('[data-modal-back]');
        backButton.addEventListener('click', () => {
            this.showExchangeList();
        });
    }

    loadExchangeData() {
        sendGetRequest(config.BASE_URL + '/exchange/list', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal('Ошибка', 'Не удалось получить данные для обмена.');
                return;
            }

            this.exchangeData = response.data;
        })
        .catch(error => {
            console.error('Ошибка загрузки данных обмена:', error);
            /* showModal('Ошибка', 'Произошла ошибка при загрузке данных обмена.'); */
        });
    }
    
    

    showExchangeForm(exchangeType) {
        this.animation.addAnimation('.exchange__form', {
            animationName: ['opacityIn'],
            duration: ['0.3'],
            delay: ['0'],
            timingFunction: ['linear']
        }); 
        setTimeout(() => {
            const modal = document.querySelector('.modal[data-modal-name="обменник"]');
            modal.querySelector('.exchange__list').style.display = 'none';
            modal.querySelector('.exchange__form').style.display = 'block';
            modal.setAttribute('data-exchange-type', exchangeType);
        }, 300);
    }

    showExchangeList() {
        this.animation.addAnimation('.exchange__form', {
            animationName: ['opacityOut'],
            duration: ['0.3'],
            delay: ['0'],
            timingFunction: ['linear']
        }); 
        setTimeout(() => {
            const modal = document.querySelector('.modal[data-modal-name="обменник"]');
            modal.querySelector('.exchange__form').style.display = 'none';
            modal.querySelector('.exchange__list').style.display = 'flex';
        }, 300);
    }

    bindExchangeFormSubmit() {
        const modal = document.querySelector('.modal[data-modal-name="обменник"]');
        const formButton = modal.querySelector('.exchange__form .btn');

        formButton.addEventListener('click', (e) => {
            e.preventDefault();
            const exchangeType = modal.getAttribute('data-exchange-type');
            const mtubeAmount = modal.querySelector('.exchange__form input[type="number"]').value;

            if (!mtubeAmount || mtubeAmount <= 0) {
                showModal('Ошибка', 'Введите корректную сумму для обмена.');
                return;
            }

            formButton.disabled = true;

            this.handleExchange(exchangeType, mtubeAmount).finally(() => {
                formButton.disabled = false;
            });
        });
    }

    resetFormButtonState(modal) {
        const formButton = modal.querySelector('.exchange__form .btn');
        formButton.disabled = false;
        formButton.classList.remove('disabled');
    }

    handleExchange(exchangeType, mtubeAmount) {
        const modal = document.querySelector('.modal[data-modal-name="обменник"]');
        const formButton = modal.querySelector('.exchange__form .btn');
    
        return sendPostRequest(config.BASE_URL + '/exchange/action', {
            "direction": exchangeType,
            "amount": mtubeAmount
        }, {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        })
        .then(response => {
            if (response.success === false) {
                showModal('Ошибка', 'Не удалось выполнить обмен.');
                this.resetFormButtonState(modal); // Сбросить состояние кнопки при ошибке
                return;
            }
    
            showModal('Успешно', 'Обмен прошёл успешно!', true);
            this.showExchangeList();
            const input = modal.querySelector('.exchange__form .input__wrapp .input__item .data input');
            input.value = '0';
            getUserStat();
        })
        .catch(error => {
            console.error('Ошибка обмена:', error);
            this.resetFormButtonState(modal); // Сбросить состояние кнопки при ошибке
        })
        .finally(() => {
            this.resetFormButtonState(modal); // Сбросить состояние кнопки в любом случае
        });
    }
    

    expandModal(modal) {
        modal.classList.add('modal--expanded');
        this.animation.addAnimation(modal, {
            animationName: ['fadeUp'],
            duration: ['0.3'],
            delay: ['0'],
            timingFunction: ['linear']
        });
    }

    collapseModal(modal) {
        modal.classList.remove('modal--expanded');
    }
}

export default ShopModal;
