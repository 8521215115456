export const data = {
    BASE_URL: process.env.BASE_URL,
    ASSET_URL: process.env.ASSET_URL,
    BOT_URL: process.env.BOT_URL,
    APP_URL: process.env.APP_URL
}

export const config = {
    BASE_URL: data.BASE_URL,
    ASSET_URL: data.ASSET_URL,
    BOT_URL: data.BOT_URL,
    APP_URL: data.APP_URL
};