import Modal from './modal.js';
import { sendGetRequest, getFromLocalStorage, showModal } from '../functions/api';
import { config } from './../../config';

class ReferralSystem extends Modal {
    constructor() {
        super();
        this.currentPage = 1;
        this.usersPerPage = 8;
        this.usersData = {}; // Здесь будут храниться загруженные данные по уровням
        this.currentLevel = 1;

        this.initReferralSystem();
    }

    initReferralSystem() {
        const inviteButton = document.querySelector('.referal__btn .primary--btn');
        const copyLinkButton = document.querySelector('.referal__btn .primary--btn + .primary--btn');
        inviteButton.removeAttribute('disabled');
        copyLinkButton.removeAttribute('disabled');

        this.setupEventListeners(inviteButton, copyLinkButton);
        this.loadReferralLevels(); // Загружаем только количество друзей по уровням
    }

    setupEventListeners(inviteButton, copyLinkButton) {
        this.initCopyLinkButton(copyLinkButton);
        this.initShareTelegramButton(inviteButton);

        document.querySelectorAll('.referal__level__item').forEach((level, index) => {
            level.addEventListener('click', () => this.openReferralModal(index + 1));
        });

        document.querySelectorAll('.pagination-btn').forEach(button => {
            button.addEventListener('click', () => this.handlePagination(button.getAttribute('data-action')));
        });
    }

    initCopyLinkButton(copyLinkButton) {
        copyLinkButton.addEventListener('click', async () => {
            const telegramUser = window.telegramUser;
            const userId = telegramUser.id;
            if (!userId) {
                console.error('User ID not found');
                return;
            }
            const linkToCopy = `${config.BOT_URL}?start=${userId}`;
            try {
                await navigator.clipboard.writeText(linkToCopy);
                console.log('Ссылка скопирована в буфер обмена!');
                showModal('Успешно', 'Ссылка скопирована в буфер обмена', true);
            } catch (err) {
                console.error('Ошибка при копировании ссылки:', err);
            }
        });
    }

    initShareTelegramButton(inviteButton) {
        inviteButton.addEventListener('click', () => {
            const telegramUser = window.telegramUser;
            const userId = telegramUser.id;
            if (!userId) {
                console.error('User ID not found');
                return;
            }
            const linkToShare = `${config.BOT_URL}?start=${userId}`;
            const url = `https://t.me/share/url?url=${encodeURIComponent(linkToShare)}`;
            window.open(url, '_blank');
        });
    }

    async loadReferralLevels() {
        try {
            const response = await sendGetRequest(`${config.BASE_URL}/referal/index`, {
                "Authorization": `Bearer ${getFromLocalStorage('token')}`
            });

            if (response.status !== 'success') throw new Error('Ошибка при загрузке данных');
            
            this.updateReferralLevels(response.data.levels);
        } catch (error) {
            console.error('Ошибка при загрузке реферальных данных:', error);
        }
    }

    updateReferralLevels(levelsData) {
        document.querySelectorAll('.referal__level__item .friends .number').forEach((element, index) => {
            element.textContent = levelsData[index + 1] || 0;
        });
    }

    async loadUsersDataForLevel(level) {
        if (this.usersData[`level${level}`]) {
            return;
        }

        try {
            const response = await sendGetRequest(`${config.BASE_URL}/referal/list?level=${level}`, {
                "Authorization": `Bearer ${getFromLocalStorage('token')}`
            });

            if (response.status === 'success') {
                this.usersData[`level${level}`] = response.data.rows;
                console.log(`Данные для уровня ${level} загружены:`, response.data.rows);
            } else {
                throw new Error('Ошибка при загрузке данных');
            }
        } catch (error) {
            console.error(`Ошибка загрузки данных для уровня ${level}:`, error);
        }
    }

    async openReferralModal(level) {
        this.currentLevel = level;
        this.currentPage = 1;

        const modal = document.querySelector('.modal[data-modal-name="referal-level-users"]');
        modal.querySelector('.modal__head').textContent = `Уровень ${level}`;

        await this.loadUsersDataForLevel(level);

        this.displayUsersForPage(level);
        this.updatePagination();
        this.openModal('referal-level-users');
    }

    displayUsersForPage(level) {
        const modal = document.querySelector('.modal[data-modal-name="referal-level-users"]');
        const usersList = modal.querySelector('.users__list');
        const users = this.usersData[`level${level}`] || [];

        usersList.innerHTML = '';

        if (users.length === 0) {
            this.displayNoData(usersList);
            return;
        }

        const startIndex = (this.currentPage - 1) * this.usersPerPage;
        const usersForPage = users.slice(startIndex, startIndex + this.usersPerPage);

        usersForPage.forEach(user => {
            const userItem = document.createElement('div');
            userItem.classList.add('user');
            userItem.innerHTML = `<p class="name">${user.username}</p><p class="date">${user.date_created}</p>`;
            usersList.appendChild(userItem);
        });
    }

    displayNoData(usersList) {
        const noDataItem = document.createElement('div');
        noDataItem.classList.add('no-data');
        noDataItem.innerHTML = '<p class="both">Нет данных</p>';
        usersList.appendChild(noDataItem);
    }

    totalPages() {
        const users = this.usersData[`level${this.currentLevel}`] || [];
        return Math.ceil(users.length / this.usersPerPage);
    }

    updatePagination() {
        const totalPages = this.totalPages();
        const pageInfo = document.querySelector('.page-info');
        if (pageInfo) {
            pageInfo.textContent = `${this.currentPage} / ${totalPages}`;
        }
    }

    handlePagination(action) {
        const totalPages = this.totalPages();

        if (action === 'first') this.currentPage = 1;
        if (action === 'prev' && this.currentPage > 1) this.currentPage--;
        if (action === 'next' && this.currentPage < totalPages) this.currentPage++;
        if (action === 'last') this.currentPage = totalPages;

        this.displayUsersForPage(this.currentLevel);
    }
}

export default ReferralSystem;
