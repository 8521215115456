export function loadSvgInline(imgElement) {
    const imgID = imgElement.id;
    const imgURL = imgElement.src;

    fetch(imgURL)
      .then(response => response.text())
      .then(svgText => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svgElement = svgDoc.querySelector('svg');

        if (imgID) {
          svgElement.id = imgID;
        }
        svgElement.classList.add('svg-colored');

        imgElement.parentNode.replaceChild(svgElement, imgElement);
      })
      .catch(error => console.error(error));
}