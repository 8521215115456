import TonWallet from './tonconnect.js';
import { sendGetRequest, sendPostRequest, getFromLocalStorage, showModal, getUserStat, saveToLocalStorage } from '../functions/api';
import { config } from './../../config';
import Modal from './modal';
import { getTranslation } from '../_functions';

class Wallet {
    constructor() {
        this.walletButton = document.querySelector('.wallet__link');
        this.walletText = this.walletButton ? this.walletButton.querySelector('.wallet__link--text') : null;
        this.tonWallet = new TonWallet();
        this.isProcessing = false;

        this.currentLanguage = getFromLocalStorage('language') || 'en';

        const storedAddress = getFromLocalStorage('wallet_adress');
        const base64 = storedAddress ? this.tonWallet.tonHexToBase64(storedAddress) : null;
        if (!storedAddress || storedAddress === null) {
            this.updateWalletUI(getTranslation(this.currentLanguage, 'header.wallet.wallet__title'));
        } else {
            this.updateWalletUI(this.tonWallet.tonHexToBase64(storedAddress));
        }

        this.setupModal();
        this.setupWalletSelection(base64);
    }

    async initWallet() {
        try {
            const walletInfo = this.tonWallet.getWalletInfo();
            if (walletInfo && walletInfo.account) {
                const base64Address = this.tonWallet.tonHexToBase64(walletInfo.account.address);
                this.updateWalletUI(base64Address);
                saveToLocalStorage('wallet_adress', walletInfo.account.address);
            } else {
                const connectedWallet = await this.tonWallet.connect();
                const base64Address = this.tonWallet.tonHexToBase64(connectedWallet.address);
                saveToLocalStorage('wallet_adress', walletInfo.account.address);
                this.updateWalletUI(base64Address);
            }
        } catch (error) {
            console.error("Ошибка при подключении:", error);
        }
    }

    async setupModal() {
        if (this.walletButton) {
            this.walletButton.addEventListener('click', async () => {
                this.initWallet();
                const walletInfo = this.tonWallet.getWalletInfo();
                if (walletInfo && walletInfo.account) {
                    const base64Address = this.tonWallet.tonHexToBase64(walletInfo.account.address);
                    Modal.showWalletModal(base64Address, {
                        onCopy: () => this.copyAddress(walletInfo.account.address),
                        onDisconnect: async () => {
                            await this.tonWallet.disconnect();
                            this.walletText.textContent = getTranslation(this.currentLanguage, 'header.wallet.wallet__title');
                        }
                    });
                } else {
                    const connectedWallet = await this.tonWallet.connect();
                    const base64Address = this.tonWallet.tonHexToBase64(connectedWallet.address);
                    this.updateWalletUI(base64Address);
                    Modal.showWalletModal(base64Address, {
                        onCopy: () => this.copyAddress(walletInfo.account.address),
                        onDisconnect: async () => {
                            await this.tonWallet.disconnect();
                            this.walletText.textContent = getTranslation(this.currentLanguage, 'header.wallet.wallet__title');
                        }
                    });
                }
            });
        }
    }

    updateWalletUI(address) {
        if (!address || address === getTranslation(this.currentLanguage, 'header.wallet.wallet__title')) {
            if (this.walletText) {
                this.walletText.textContent = getTranslation(this.currentLanguage, 'header.wallet.wallet__title');
            }
            return;
        }
    
        try {
            const isAddressValid = address.length >= 20;
            const shortAddress = isAddressValid 
                ? `${address.slice(0, 4)}...${address.slice(-4)}` 
                : address;
    
            if (this.walletText) {
                this.walletText.textContent = shortAddress;
            }
        } catch (error) {
            console.error("Ошибка преобразования адреса:", error);
            if (this.walletText) {
                this.walletText.textContent = "Error";
            }
        }
    }
     
    copyAddress(address) {
        navigator.clipboard.writeText(this.tonWallet.tonHexToBase64(address)).then(() => {
            alert("Wallet is copy to clipboard!");
        }).catch((err) => {
            console.error("Ошибка при копировании адреса:", err);
        });
    }

    setupWalletSelection(addresses) {
        const walletInput = document.getElementById('wallet-combo');
        const walletDropdown = document.getElementById('wallet-dropdown');
        const walletError = document.getElementById('wallet-error');
        const clearIcon = document.querySelector('.clear-icon');

        const wallets = new Set();

        const updateWalletList = (address) => {
            if (!address || typeof address !== 'string') return;
        
            if (wallets.has(address)) return;
        
            wallets.add(address);
        
            if (Array.from(walletDropdown.children).some(li => li.dataset.address === address)) {
                return;
            }
        
            const li = document.createElement('li');
            li.textContent = `${address.slice(0, 4)}...${address.slice(-4)}`;
            li.dataset.address = address;
        
            walletDropdown.appendChild(li);
        
            li.addEventListener('click', () => {
                walletInput.value = li.dataset.address;
                walletDropdown.style.display = 'none';
                walletError.style.display = 'none';
            });
        };

        
        
        
        [addresses].forEach(updateWalletList);

        walletInput.addEventListener('focus', () => {
            walletDropdown.style.display = 'block';
            if (window.innerWidth <= 768){
                $('.modal--active .modal__content').addClass('exchange__form--shifted');
            }
        });

        document.addEventListener('click', (e) => {
            if (!walletDropdown.contains(e.target) && e.target !== walletInput) {
                if (window.innerWidth <= 768){
                    $('.modal--active .modal__content').removeClass('exchange__form--shifted');
                }
                walletDropdown.style.display = 'none';
            }
        });

        walletInput.addEventListener('input', () => {
            const inputValue = walletInput.value.trim();
            const isValidAddress = inputValue.length >= 20;

            walletError.style.display = isValidAddress ? 'none' : 'block';
        });

        clearIcon.addEventListener('click', () => {
            walletInput.value = '';
            walletError.style.display = 'none';
        });
    }
    
}

export default Wallet;