import { auth, getUserStat, getFromLocalStorage, sendPostRequest, showModal, saveToLocalStorage } from '../functions/api';
import { config } from './../../config';

class Bonus {
    constructor() {
        this.initBonus();
    }

    async initBonus() {
        await this.getUserStats();

        this.initBonusTimer();

        this.bindBonusButton();
    }

    async getUserStats() {
        try {
            const response = await getUserStat();
            const lastBonusDate = getFromLocalStorage('last_bonus_date');

            if (lastBonusDate) {
                const canClaim = this.canClaimBonus(lastBonusDate);
                this.updateBonusButton(canClaim);
            }
        } catch (error) {
            console.error('Ошибка при получении статистики пользователя:', error);
        }
    }

    canClaimBonus(lastBonusDate) {
        const currentDate = new Date();
        const lastBonusDateTime = new Date(lastBonusDate.replace(' ', 'T') + 'Z');

        return currentDate.getUTCDate() !== lastBonusDateTime.getUTCDate();
    }

    updateBonusButton(canClaim) {
        const bonusBtn = document.getElementById('bonus-btn');
        if (canClaim) {
            bonusBtn.removeAttribute('disabled');
        } else {
            bonusBtn.setAttribute('disabled', 'true');
        }
    }

    bindBonusButton() {
        const bonusBtn = document.getElementById('bonus-btn');
        bonusBtn.addEventListener('click', async () => {
            await this.claimBonus();
        });
    }

    async claimBonus() {
        try {
            const response = await sendPostRequest(config.BASE_URL + '/user/get-bonus', {}, {
                Authorization: 'Bearer ' + getFromLocalStorage('token')
            });

            if (response.status === 'success' && response.data.result) {
                const likesReceived = response.data.claimed;
                showModal('Ежедневный бонус', likesReceived + ' ' +`<img src="img/svg/currency/like.svg" alt="">`, true);
                saveToLocalStorage('last_bonus_date', new Date().toISOString());
                getUserStat()

                this.updateBonusButton(false);

                this.initBonusTimer();
            } else {
                showModal('Получение бонуса', 'Ещё рано собирать бонус.');
            }
        } catch (error) {
            showModal('Ошибка', 'Произошла ошибка при получении бонуса.');
            console.error('Ошибка при получении бонуса:', error);
        }
    }

    initBonusTimer() {
        const lastBonusDate = getFromLocalStorage('last_bonus_date');
        if (lastBonusDate) {
            const timerElement = document.getElementById('bonus-timer');
            const nextBonusTime = this.getNextBonusTime(lastBonusDate);
            let timerInterval;

            const currentDate = new Date();
            const lastBonusDateTime = new Date(lastBonusDate.replace(' ', 'T') + 'Z');

            const updateTime = () => {
                const timeLeft = nextBonusTime - new Date();

                if (timeLeft > 0) {
                    timerElement.textContent = this.formatTime(timeLeft);
                } else {
                    timerElement.textContent = '00:00:00';
                    clearInterval(timerInterval);
                    this.updateBonusButton(true);
                }
            };

            if (currentDate.getUTCDate() !== lastBonusDateTime.getUTCDate()) {
                const nextBonusDate = new Date();
                nextBonusDate.setUTCHours(0, 0, 0, 0);
                nextBonusDate.setUTCDate(nextBonusDate.getUTCDate() + 1);
                return nextBonusDate;
            }

            updateTime();
            timerInterval = setInterval(updateTime, 1000);
        }
    }

    getNextBonusTime(lastBonusDate) {
        const currentDate = new Date();
        const lastBonusDateTime = new Date(lastBonusDate.replace(' ', 'T') + 'Z');

        if (currentDate.getUTCDate() !== lastBonusDateTime.getUTCDate()) {
            const nextBonusDate = new Date();
            nextBonusDate.setUTCHours(0, 0, 0, 0);
            nextBonusDate.setUTCDate(nextBonusDate.getUTCDate() + 1);
            return nextBonusDate;
        }

        const nextBonusDate = new Date();
        nextBonusDate.setUTCHours(0, 0, 0, 0);
        nextBonusDate.setUTCDate(nextBonusDate.getUTCDate() + 1);
        return nextBonusDate;
    }

    formatTime(milliseconds) {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
}

export default Bonus;
