import Animation from './animations.js';

class Modal {
    constructor() {
        this.modals = document.querySelectorAll('.modal');
        this.bindButtons();
        this.animation = new Animation();
    }

    bindButtons() {
        document.querySelectorAll('[data-modal]').forEach(button => {
            const modalName = button.getAttribute('data-modal');
            button.addEventListener('click', () => {
                this.openModal(modalName);
            });
        });

        this.modals.forEach(modal => {
            const closeButtons = modal.querySelectorAll('[data-modal-close]');
            
            closeButtons.forEach(closeButton => {
                closeButton.addEventListener('click', () => {
                    this.closeModal(modal);
                });
            });

            modal.addEventListener('click', (event) => {
                if (event.target === modal) {
                    this.closeModal(modal);
                }
            });
        });
    }

    openModal(modalName) {
        const modal = document.querySelector(`.modal[data-modal-name="${modalName}"]`);
        if (modal) {
            modal.classList.add('modal--active');
            document.body.classList.add('no-scroll');
            this.animation.addAnimation(modal, {
                animationName: ['opacityIn'],
                duration: ['0.3'],
                delay: ['0'],
                timingFunction: ['linear']
            }); 
            this.animation.addAnimation('[data-modal-name="купить-лайки"] .modal__content', {
                animationName: ['fadeUp'],
                duration: ['0.4'],
                delay: ['0'],
                timingFunction: ['linear']
            }); 
        }
    }

    closeModal(modal) {
        this.animation.addAnimation(modal, {
            animationName: ['opacityOut'],
            duration: ['0.3'],
            delay: ['0'],
            timingFunction: ['linear']
        }); 
        this.animation.addAnimation('[data-modal-name="купить-лайки"] .modal__content', {
            animationName: ['fadeDown'],
            duration: ['0.3'],
            delay: ['0'],
            timingFunction: ['linear']
        }); 

        setTimeout(() => {
            modal.classList.remove('modal--active');
            document.body.classList.remove('no-scroll');
        }, 300);
    }

    closeModalByName(modalName) {
        const modal = document.querySelector(`.modal[data-modal-name="${modalName}"]`);
        if (modal) {
            this.closeModal(modal);
        }
    }
}

export default Modal;
