class Animation {
    constructor() {
        this.initHTMLAnimations();
    }

    addAnimation(selector, { animationName, duration, delay, timingFunction, iteration = '1' }) {
        let elements;
    
        if (typeof selector === 'string') {
            elements = document.querySelectorAll(selector);
        } 
        else if (selector instanceof HTMLElement) {
            elements = [selector];
        } 
        else if (selector instanceof NodeList || Array.isArray(selector)) {
            elements = selector;
        } else {
            throw new Error('Invalid selector provided to addAnimation');
        }
    
        elements.forEach(element => {
            element.style.animationName = animationName;
            element.style.animationDuration = `${duration}s`;
            element.style.animationDelay = `${delay}s`;
            element.style.animationTimingFunction = timingFunction;
            element.style.animationIterationCount = iteration;
    
            element.addEventListener('animationend', () => {
                element.style.animationName = '';
            });
        });
    }    

    initHTMLAnimations() {
        const elements = document.querySelectorAll('[data-animation]');
        elements.forEach(element => {
            const animationName = element.getAttribute('data-animation');
            const duration = element.getAttribute('data-duration') || '1';
            const delay = element.getAttribute('data-delay') || '0';
            const timingFunction = element.getAttribute('data-timing-function') || 'ease';
            const iteration = element.getAttribute('data-iteration') || '1';

            element.style.animationName = animationName;
            element.style.animationDuration = `${duration}s`;
            element.style.animationDelay = `${delay}s`;
            element.style.animationTimingFunction = timingFunction;
            element.style.animationIterationCount = iteration;

            element.addEventListener('animationend', () => {
                element.style.animationName = '';
            });
        });
    }
}

export default Animation;
