export const data = {
    BASE_URL: process.env.BASE_URL,
    ASSET_URL: process.env.ASSET_URL,
    BOT_URL: process.env.BOT_URL
}

export const config = {
    BASE_URL: data.BASE_URL || "http://localhost:85/api",
    ASSET_URL: data.ASSET_URL || "http://localhost:85",
    BOT_URL: data.BOT_URL || "https://t.me/MoneyTube_bot"
};