import { sendGetRequest, sendPostRequest, getFromLocalStorage, showModal, getUserStat } from '../functions/api';
import { config } from './../../config';
import TonWallet from './tonconnect.js';
import Wallet from './wallet.js';
import { getTranslation } from '../_functions';

class TaskList {
    constructor() {
        this.isTaskProcessing = false;
        this.clickCooldown = {};
        this.isActive = false;
        this.taskPollingInterval = null;
        this.isFetching = false;
        this.refreshInterval = 10000;
        this.isModalOpen = false;
        this.tonWallet = new TonWallet();
        this.Wallet = new Wallet();
        this.activeTaskId = null;
        this.currentLanguage = getFromLocalStorage('language') || 'en';

    }

    init() {
        this.refreshTaskList();
        this.startPollingTasks();
        
        const addAdButton = document.querySelector('.telegram-finance');
        if (addAdButton) {
            addAdButton.addEventListener('click', () => this.openTelegramChannel());
        }
    }

    openTelegramChannel() {
        const telegramUrl = 'https://t.me/MoneyTubeFinance';
        this.openLink(telegramUrl);
    }

    activate() {
        if (!this.isActive) {
            this.isActive = true;
            this.init();
        }
    }

    startPollingTasks() {
        if (!this.taskPollingInterval) {
            this.taskPollingInterval = setInterval(() => this.refreshTaskList(), this.refreshInterval);
        }
    }

    stopPollingTasks() {
        if (this.taskPollingInterval) {
            clearInterval(this.taskPollingInterval);
            this.taskPollingInterval = null;
        }
    }

    deactivate() {
        this.isActive = false;
        clearInterval(this.taskPollingInterval);
    }

    refreshTaskList() {
        if (!this.isActive || this.isFetching) return;

        this.isFetching = true;

        sendGetRequest(config.BASE_URL + '/task/list', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        }).then(response => {
            this.isFetching = false;
            if (response.success === false) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'task.modal.text.load__task__error'));
                return;
            }
            this.renderTaskList(response.data);
            this.updateUserStats();
        }).catch(() => {
            this.isFetching = false;
        });
    }

    renderTaskList(tasks) {
        const taskListContainer = document.querySelector('.tasks__list');
    
        tasks.sort((a, b) => {
            return (a.is_finished - b.is_finished) || ((a.type === 'checkout') - (b.type === 'checkout')) || b.is_finished - a.is_finished;
        });
    
        tasks.forEach(task => {
            const existingTaskElement = taskListContainer.querySelector(`.tasks__item[data-id="${task.id}"]`);
            if (existingTaskElement) {
                this.updateTaskStatusDisplay(existingTaskElement, task);
            } else {
                taskListContainer.insertAdjacentHTML('beforeend', this.generateTaskItem(task));
                this.bindTaskClickHandler(task);
            }
        });
    
        this.removeObsoleteTasks(taskListContainer, tasks);
        this.checkAndStartPolling(tasks);
    }
    

    updateTaskStatusDisplay(taskElement, task) {
        const newStatus = task.is_finished ? 'checked' : (task.is_started ? 'load' : 'new');
        if (taskElement.getAttribute('status') !== newStatus) {
            taskElement.setAttribute('status', newStatus);
            taskElement.querySelector('.status').innerHTML = this.getStatusIcon(newStatus);
        }
    }

    removeObsoleteTasks(container, tasks) {
        const taskIds = tasks.map(task => task.id);
        container.querySelectorAll('.tasks__item').forEach(taskElement => {
            if (!taskIds.includes(parseInt(taskElement.getAttribute('data-id'), 10))) {
                taskElement.remove();
            }
        });
    }

    generateTaskItem(task) {
        const taskStatus = task.is_finished ? 'checked' : (task.is_started ? 'load' : 'new');
        const statusIcon = this.getStatusIcon(taskStatus);
        return `
            <div class="tasks__item" status="${taskStatus}" data-id="${task.id}" data-url="${task.url}" data-status="${task.is_started}">
                <div class="tasks__item--info">
                    <div class="icon">
                        <img src="${config.ASSET_URL + task.icon}" alt="" onerror="this.src='img/profile/avatar.png';">
                    </div>
                    <div class="info">
                        <p class="title">${task.title}</p>
                        <div class="reward">
                            <img src="img/svg/currency/like.svg" alt="">
                            <p class="count">+${task.executor_reward} likes</p>
                        </div>
                    </div>
                </div>
                <div class="status">${statusIcon}</div>
            </div>`;
    }

    bindTaskClickHandler(task) {
        const taskElement = document.querySelector(`.tasks__item[data-id="${task.id}"]`);
    
        const completionButton = taskElement.querySelector('.get-reward-btn');
        if (completionButton) {
            completionButton.addEventListener('click', (event) => {
                this.handleTaskCompletion(event.target);
            });
        }
    
        taskElement.addEventListener('click', (event) => {
            event.preventDefault();
            
            if (this.isTaskProcessing) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.warning'), getTranslation(this.currentLanguage, 'task.modal.text.task__processed'));
                return;
            }

            const isWalletConnected = getFromLocalStorage('wallet_adress');
            if (!isWalletConnected || isWalletConnected === null) {
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'modal.text.wallet__connect'));
                return;
            }
    
            if (task.is_finished) {
                showModal('Task Completed', 'The task is already completed.', true);
                return;
            }
    
            if (task.is_started) {
                this.isTaskProcessing = true;
    
                showModal(getTranslation(this.currentLanguage, 'modal.title.warning'), getTranslation(this.currentLanguage, 'task.modal.text.task__processed'));
                this.isTaskProcessing = false;
                return;
            }
    
            this.processTask(task, taskElement);
        });
    }
     


    processTask(task, taskElement, clickCount) {
        taskElement.setAttribute('disabled', 'true');
        this.isTaskProcessing = true;

        this.processTaskRequest(task.id, true).then((additionalInfo) => {
            task.additional_info = additionalInfo;

            const handlers = {
                1: this.handleChannelSub,
                2: this.handleGameStart,
                3: this.handleTonPaymentTask,
                4: this.handleStarsTransfer,
                5: this.handleInviteFriends,
                6: this.handleAdIntegration,
                7: this.handleOtherTask

            };

            (handlers[task.type] || this.handleOtherTask).call(this, task, additionalInfo);
            this.finalizeTaskProcess(task, taskElement, clickCount);
        }).catch(() => this.resetTaskProcess(taskElement, clickCount));
    }

    processTaskRequest(taskId, isStart) {
        return sendPostRequest(config.BASE_URL + '/task/process', { id: taskId, is_start: isStart }, {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        }).then(response => {
            if (response.success === false) {
                this.showErrorModal(response.data.message || getTranslation(this.currentLanguage, 'task.modal.text.failed__complete'));
                throw new Error('Task processing failed');
            } else {
                return response.data.additional_info;
            }
        });
    }

    showErrorModal(message) {
        if (this.isModalOpen) return;
        this.isModalOpen = true;
        showModal(getTranslation(this.currentLanguage, 'modal.title.error'), message, () => {
            this.isModalOpen = false;
        });
    }
    

    finalizeTaskProcess(task, taskElement, clickCount) {
        this.refreshTaskList();
        this.setTaskTimeout(task.id);
        this.clickCooldown[task.id] = setTimeout(() => {
            delete this.clickCooldown[task.id];
            taskElement.removeAttribute('disabled');
        }, 3000);
    
        setTimeout(() => {
            this.isTaskProcessing = false;
            clickCount = 0;
        }, 4000);
    }
    

    resetTaskProcess(taskElement, clickCount) {
        this.isTaskProcessing = false;
        taskElement.removeAttribute('disabled');
        clickCount = 0;
    }

    setTaskTimeout(taskId) {
        setTimeout(() => this.updateTaskStatus(), 3600000);
    }

    updateTaskStatus() {
        if (this.isFetching) return;

        this.isFetching = true;
        sendGetRequest(config.BASE_URL + '/task/list', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        }).then(response => {
            this.isFetching = false;
            if (!response.success === false) {
                response.data.forEach(task => this.changeTaskStatus(task.id, task.is_finished ? 'checked' : (task.is_started ? 'load' : 'new')));
            }
        }).catch(() => this.isFetching = false);
    }

    changeTaskStatus(taskId, newStatus) {
        const taskElement = document.querySelector(`.tasks__item[data-id="${taskId}"]`);
        if (taskElement) {
            taskElement.setAttribute('status', newStatus);
            taskElement.querySelector('.status').innerHTML = this.getStatusIcon(newStatus);
        }
    }

    handleTaskCompletion(buttonElement) {
        const taskElement = buttonElement.closest('.tasks__item');
        const taskId = taskElement.getAttribute('data-id');


        this.processTaskRequest(taskId, false).then(() => {
            this.refreshTaskList();
        }).catch(() => {
            buttonElement.removeAttribute('disabled');
            this.showErrorModal(getTranslation(this.currentLanguage, 'task.modal.text.failed__complete'));
        });
    }

    getStatusIcon(status) {
        const icons = {
            load: `<button class="btn primary--btn get-reward-btn">GET</button>`,
            checked: `<img src="img/svg/success.svg" alt="">`,
            new: `<img src="img/svg/arrow-right.svg" alt="">`,
        };
        return icons[status] || icons.new;
    }

    handleChannelSub(task, additionalInfo) { this.openLink(additionalInfo.url || task.params.url); }
    handleGameStart(task, additionalInfo) { this.openLink(additionalInfo.game_url || task.params.url); }
    handleTonTransfer(task, additionalInfo) { this.openLink(additionalInfo.payment_link || task.params.payment_link); }
    handleStarsTransfer(task, additionalInfo) { this.openLink(additionalInfo.payment_link || task.params.payment_link); }
    handleAdIntegration(task, additionalInfo) { this.openLink(additionalInfo.url || task.params.url); }
    handleOtherTask(task, additionalInfo) { this.openLink(task.params.url); }
    handleInviteFriends(task, additionalInfo) {
        const telegramUser = window.telegramUser;
        const userId = telegramUser ? telegramUser.id : null;

        if (!userId) {
            console.error('User ID not found');
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'task.modal.text.failed__userid'));
            return;
        }

        const referralLink = `${config.BOT_URL}?start=${userId}`;
        
        showModal(
            getTranslation(this.currentLanguage, 'task.modal.title.invite__task'),
            getTranslation(this.currentLanguage, 'task.modal.text.invite__task'),
            true,
            referralLink
        );
    }
    async handleTonPaymentTask(task) {
        const taskElement = document.querySelector(`.tasks__item[data-id="${task.id}"]`);
        const rewardButton = taskElement?.querySelector('.get-reward-btn');
    
        if (rewardButton && !task.is_finished) {
            rewardButton.click();
            this.updateUserStats();
            return;
        }
    
        const isWalletConnected = getFromLocalStorage('wallet_adress');
        if (!isWalletConnected || isWalletConnected === null) {
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'modal.text.wallet__connect'));
            return;
        }
    
        if (this.activeTaskId && this.activeTaskId !== task.id) {
            console.log("Отмена предыдущей транзакции...");
            this.activeTaskId = null;
        }
    
        this.activeTaskId = task.id;
    
        const amount = task.params.amount;
        const toAddress = task.params.wallet;
    
        try {
            const result = await this.tonWallet.sendTonPayment(amount, toAddress);
            const status = result.status === 'success' ? 'success' : 'error';
            const telegramUser = window.telegramUser;
            const player = telegramUser ? telegramUser.id : null;
    
            await this.verifyTaskCompletion(task.id, result, status, player, amount);
    
            if (status === 'success') {
                showModal(getTranslation(this.currentLanguage, 'task.modal.title.transaction__completed'), getTranslation(this.currentLanguage, 'task.modal.text.transaction__completed'), true);
            } else {
                const errorMessage = result?.error || getTranslation(this.currentLanguage, 'task.modal.text.transaction__failed');
                showModal(getTranslation(this.currentLanguage, 'modal.title.error'), errorMessage);
            }
            this.refreshTaskList();
        } catch (error) {
            console.error("Ошибка при выполнении платежа:", error);
            await this.verifyTaskCompletion(task.id, { error: error.message }, 'error');
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'task.modal.text.transaction__failed'));
        } finally {
            this.activeTaskId = null;
        }
    }
    
    
    async verifyTaskCompletion(task_id, transactionData, status, player, amount) {
        try {
            const response = await sendPostRequest(config.BASE_URL + '/payment/make', {
                task_id,
                transactionData,
                status,
                player,
                amount
            }, {
                "Authorization": "Bearer " + getFromLocalStorage('token')
            });
            /* this.refreshTaskList(); */
        } catch (error) {
            console.error("Ошибка при проверке выполнения задания:", error);
            showModal(getTranslation(this.currentLanguage, 'modal.title.error'), getTranslation(this.currentLanguage, 'modal.text.server__no__data'));
        }
    }
        

    async ensureWalletConnected() {
        const wallets = await this.tonWallet.getWallets();
        const isConnected = !!wallets.find(wallet => wallet.connected);
        
        if (!isConnected) {
            this.Wallet.showWallets(wallets);
            return false;
        }
        return true;
    }    

    openLink(url) {
        const isIOS = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints > 0;
        console.log('Device:', navigator.userAgent);
        console.log('Opening link:', url);
        if (isIOS) {
            window.location.href = url;
        } else {
            window.open(url, '_blank');
        }
    }
    

    checkAndStartPolling(tasks) {
        const hasActiveTasks = tasks.some(task => task.is_started && !task.is_finished);

        if (hasActiveTasks && !this.taskPollingInterval) {
            this.startPollingTasks();
        } else if (!hasActiveTasks) {
            this.stopPollingTasks();
        }
    }

    updateUserStats() {
        getUserStat();
    }
}

export default TaskList;